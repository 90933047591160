import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import moment from 'moment'
import Layout from '../components/Layout';
import { graphql } from "gatsby"
import PostContentBody from '../components/PostContentBody';
import SEO from '../components/SEO';

export const query = graphql`
  query MyQuery {
    posts: allSanityPost {
      edges {
        node {
          _id
          author {
            _rawImage
            _rawBio
          }
          _createdAt
          _rawBody
          title
          slug {
            current
          }
          publishedAt
        }
      }
    }
  }
`

const blog = (props) => {

  const { data, errors } = props

  if (errors) {

  }
  

    return (
      <Layout>
        <SEO title="Blog" />
        <div className="bg-gray-50">
          <StaticImage
            alt="insight driven media planning"
            src="https://res.cloudinary.com/appnet/image/upload/c_scale,q_100,w_9891/v1628681072/proscaenia/blog.png"
            placeholder="blurred"
            //   className="rounded-t-xl"
          />

          <div className="bg-gray-50">
            <div className="flex flex-col-reverse lg:flex-row px-8 lg:px-32">
              <aside className=" flex flex-col w-full lg:w-1/3 px-8 py-8 sticky">
                <h1 className="text-xl">Recent Posts Worth Reading</h1>
                {data &&
                  data.posts.edges.map((item, key) => {
                    return (
                      <a href={`/blog/${item.node.slug.current}`}
                        className="text-black no-underline flex flex-col py-6 px-4 hover:bg-white transition-all ease-in cursor-pointer rounded-md"
                        key={key}
                      >
                        <p className="text-sm capitalize">{item.node.title}</p>
                        <small className="text-gray-500 pt-4">
                          {moment(item.node.publishedAt).format(
                            "DD, MMMM YYYY"
                          )}
                        </small>
                      </a>
                    )
                  })}

             
              </aside>

              <article className="sanity-p flex flex-col w-full lg:w-2/3 bg-white rounded-md -top-20 md:-top-48 xl:-top-64 px-4 lg:px-12 py-8 lg:py-16 relative">
                <h1 className="text-3xl lg:text-5xl pb-10">
                  {data && data.posts.edges[0].node.title}
                </h1>
                <PostContentBody
                  portableTextContent={data && data.posts.edges[0].node._rawBody}
                />
              </article>
            </div>
          </div>
        </div>
      </Layout>
    )
}

export default blog
